<template>
    
    <v-tabs  centered>
    
    <v-tab href="#tab-1" class="vtabsvuetyfy">
        Список проектов
    </v-tab>
        
    <v-tab-item value="tab-1">
    <div>
        <dxDataGrid
                :PageSize = "50"
                :ShowPageSizeSelector = "true"
                :AllowedPageSizes = "[ 50, 100, 200 ]"
                :ColumnSettingsIN = "[
                        { colDataField: 'id', colCaption: 'ID', sortOrder: undefined },
                        { colDataField: 'strAbout', colCaption: 'Описание', sortOrder: undefined },
                        { colDataField: 'strPaymentsName', colCaption: 'Предложение в ГК', sortOrder: undefined },
                        { colDataField: 'numFullPrice', colCaption: 'Сумма сбора', sortOrder: undefined },
                        { colDataField: 'strComment', colCaption: 'Комментарий', sortOrder: undefined },
                        { colDataField: 'dtDate', colCaption: 'Дата', sortOrder: 'desc', visible: true },
                ]"

                HeaderText = "Проекты"
                HeaderIcon = "mdi mdi-human-greeting-proximity"
                :EditedItem = "{ strSchoolName: '' }"
                :DefaultItem = "{ strSchoolName: '' }"
                :AddFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                :EditedFields = "[
                                    //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                                ]"
                EndPoint="/test"
                :ParentParams="{ client: this.userID }"
                :GroupPanelVisible = "true"
                :HeaderFilterVisible = "false"
                :FilterRowVisible = "false"
                :SearchVisible = "false"
                :SearchWidth = "160"
                :SummaryVisible = "false"
                :BtnRefresh="true"
                :BtnAdd="true"
                :BtnEdit="true"
                :BtnDelete="true"
                :BtnShipper="false"
                :MaxWidth="800"
                :MaxGridWidth="'max-width: 90%'"
        ></dxDataGrid>
        
    </div>
    </v-tab-item>

    
    
    </v-tabs>
</template>

<script>
    import dxDataGrid from '@/components/dxDataGridF.vue'

    export default {
        name: "projectForm",
        data() {
            return {
                userID: this.$store.getters.UserId,
                selectedItemC: null,
            }
        },
        watch: {
            selectedItemC(){
                this.selectedContract = this.selectedItemC
                //console.log(this.selectedContract);
            }
        },
        computed: {
        },
        beforeMount() {
            this.selectedItemC = 0
        },
        mounted() {
        },
        components:{
            dxDataGrid
        },
        methods: {
        }
    }
</script>


