import axios from 'axios'
import date from 'date-and-time';
//import router from '../router/router.js'
import jobProjects from './jobProjects_help'
import store from './store.js'
const { Buffer } = require("buffer");

export default {
    state: {
        jobProjects: [],
        jobProjectsById: []
    },
    mutations: {
        loadjobProjects (state, payload) {
            state.jobProjects = payload
        },
        loadjobProjectsById (state, payload) {
            state.jobProjectsById = payload
        },
    },
    actions: {
        async loadjobProjects ({commit}) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const jobProjectsArr = []

            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.ApiKey,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlVSE + 'getJobProjects',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]

                    let DDZ = new Date(s.dtDate);
                    let dtStart = '';
                    if(s.dtDate !== undefined){
                        dtStart = date.format(DDZ, 'YYYY-MM-DD');
                    }
                    else{
                        dtStart = '';
                    }

                    //console.log(s)
                    jobProjectsArr.push(
                        new jobProjects(
                            s.id.trim(), 
                            s.strAbout.trim(),
                            s.strPaymentsName.trim(),
                            s.numFullPrice,
                            s.strComment.trim(),
                            dtStart
                        ) 
                    )
                        
                })
                //console.log(jobProjectsArr)

                
                commit('loadjobProjects', jobProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('loadjobProjects', [])
                commit('setLoading2', false)
                commit('setError', response)

                
            });

        },
        

        async loadjobProjectsById ({commit}, { strProjectUUID }) {
            //console.log('1');
            commit('clearError')
            commit('setLoading2', true)

            //const dtStart = date.format(dtStart, 'YYYY-MM-DD');
            //const dtEnd = date.format(dtEnd, 'YYYY-MM-DD');
            const jobProjectsArr = []

            //console.log(strProjectUUID)
            
            let data = []
            
            await axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.ApiKey,
                    'projUUID': strProjectUUID,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlVSE + 'getJobProjectsById',
                data: data

            })
            .then(async (response) => {
                
                //console.log(response.data)

                const pgSQLData = JSON.parse(Buffer.from(response.data, 'base64').toString('utf-8'));
                //const pgSQLData = response.data;

                //console.log(pgSQLData)

                Object.keys(pgSQLData).forEach(key => {
                    const s = pgSQLData[key]

                    let DDZ = new Date(s.dtDate);
                    let dtStart = '';
                    if(s.dtDate !== undefined){
                        dtStart = date.format(DDZ, 'YYYY-MM-DD');
                    }
                    else{
                        dtStart = '';
                    }

                    //console.log(s)
                    jobProjectsArr.push(
                        new jobProjects(
                            s.id.trim(), 
                            s.strAbout.trim(),
                            s.strPaymentsName.trim(),
                            s.numFullPrice,
                            s.strComment.trim(),
                            dtStart
                        ) 
                    )
                        
                })
                //console.log(jobProjectsArr)
                
                commit('loadjobProjectsById', jobProjectsArr)
                commit('setLoading2', false)
            })
            .catch((response) => {
                //console.log(response)
                var err = Object.assign({}, response)
                console.log('error', err.response);
                                
                commit('jobProjectsById', [])
                commit('setLoading2', false)
                commit('setError', response)

                
            });

        },
        
    },
    
    getters: {
        jobProjects (state) {
            return state.jobProjects
        },
        jobProjectsById (state) {
            return state.jobProjectsById
        }
    }
}