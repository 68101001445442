export default class jobProjects {
    constructor (
      id, 
      strAbout,
      strPaymentsName,
      numFullPrice,
      strComment,
      dtDate
    ) {
      this.id = id
      this.strAbout = strAbout
      this.strPaymentsName = strPaymentsName
      this.numFullPrice = numFullPrice
      this.strComment = strComment
      this.dtDate = dtDate
    }
  }