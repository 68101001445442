var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tabs',{attrs:{"centered":""}},[_c('v-tab',{staticClass:"vtabsvuetyfy",attrs:{"href":"#tab-1"}},[_vm._v(" Список проектов ")]),_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('div',[_c('dxDataGrid',{attrs:{"PageSize":50,"ShowPageSizeSelector":true,"AllowedPageSizes":[ 50, 100, 200 ],"ColumnSettingsIN":[
                    { colDataField: 'id', colCaption: 'ID', sortOrder: undefined },
                    { colDataField: 'strAbout', colCaption: 'Описание', sortOrder: undefined },
                    { colDataField: 'strPaymentsName', colCaption: 'Предложение в ГК', sortOrder: undefined },
                    { colDataField: 'numFullPrice', colCaption: 'Сумма сбора', sortOrder: undefined },
                    { colDataField: 'strComment', colCaption: 'Комментарий', sortOrder: undefined },
                    { colDataField: 'dtDate', colCaption: 'Дата', sortOrder: 'desc', visible: true },
            ],"HeaderText":"Проекты","HeaderIcon":"mdi mdi-human-greeting-proximity","EditedItem":{ strSchoolName: '' },"DefaultItem":{ strSchoolName: '' },"AddFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EditedFields":[
                                //{ model: 'strSchoolName', label: 'Наименование', readonly: false, typeField: 'text', dialog: 'Добавить, Редактировать', sizeField: 'xs12', counterField: '', maskField: '' }
                            ],"EndPoint":"/test","ParentParams":{ client: this.userID },"GroupPanelVisible":true,"HeaderFilterVisible":false,"FilterRowVisible":false,"SearchVisible":false,"SearchWidth":160,"SummaryVisible":false,"BtnRefresh":true,"BtnAdd":true,"BtnEdit":true,"BtnDelete":true,"BtnShipper":false,"MaxWidth":800,"MaxGridWidth":'max-width: 90%'}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }