<template>
    <div id="app">
        <v-app id="inspire">
            <v-main>
                <v-container fluid fill-height>
                    <v-layout align-center justify-center>
                        <v-flex xs12 sm8 md4>
                            <v-card class="elevation-12">
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>Все Все Вместе (Проекты)</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field prepend-icon="mdi-account" name="login" label="E-mail пользователя" type="text" v-model="name"></v-text-field>
                                        <v-text-field prepend-icon="mdi-lock" name="password" label="Пароль" id="password" type="password" v-model="password"></v-text-field>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <vue-recaptcha
                                        ref="recaptcha"
                                        size="invisible"
                                        :sitekey="sitekey"
                                        @verify="loginUserCheck"
                                        @expired="onCaptchaExpired"
                                    />
                                    <v-btn color="primary" @click="validate" :disabled="disabled == 1">Вход</v-btn>
                                </v-card-actions>
                            </v-card>
                            <div>
                                <v-alert
                                        v-model="alert"
                                        dismissible
                                        type="error"

                                >
                                    {{ errorMsg }}
                                </v-alert>
                                <v-alert
                                        v-model="info"
                                        dismissible
                                        type="info"

                                >
                                    {{ infoMsg }}
                                </v-alert>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-main>
        </v-app>
    </div>
</template>
<script>
    //import Vue from 'vue'
    //import axios from 'axios'
    import * as sha512 from 'js-sha512'
    import date from 'date-and-time';
    import Vue from 'vue'

    import VueRecaptcha from 'vue-recaptcha'

    export default {
        name: "loginForm",
        components: { VueRecaptcha },
        data() {
            return {
                name: null,
                password: null,
                errorMsg: "",
                infoMsg: "",
                alert: false,
                info: false,
                dt: "",
                disabled: 0,
                sitekey: '6LeZwZgbAAAAAHNUOQLIV365LEBp0Cx8Te5S5F98'
            }
        },
        mounted() {
            const now = new Date();
            this.dt = date.format(now, 'YYYY-MM-DD HH:mm:ss');
        },
        methods: {
            validate () {
            // тут можно добавить проверку на валидацию
            // например, с помощью vee validate
            // если с валидацией наших полей все хорошо, запускаем каптчу
                this.$refs.recaptcha.execute()
            },
            onCaptchaExpired () {
                this.$refs.recaptcha.reset()
            },
            async loginUserCheck(recaptchaToken) {
                this.disabled = 1
                this.alert = false
                
                let shaPass = sha512.sha512(this.password);

                const user = {
                    email: this.name,
                    password: shaPass,
                    recaptchaToken: recaptchaToken
                }
                this.info = true
                this.infoMsg = 'Проверка пользователя...'
                //console.log(user)
                //site key//6LeZwZgbAAAAAHNUOQLIV365LEBp0Cx8Te5S5F98
                //secret key//6LeZwZgbAAAAAMoiP4QLy2zk6W9-Us7Oj9hn0zg0
                await this.$store.dispatch('loginUser', user)
                    .then(async () => {
                        //console.log(1)
                        this.$store.commit('SetUserMail', this.name)
                        this.infoMsg = 'Проверка пользователя...'
                        //console.log(this.$store.getters.UserMail)
                        //console.log(this.$store.getters.IsAdmin)
                        //console.log(this.$store.getters.IsSchoolAdmin)
                        //console.log(this.$store.getters.UserProjID)
                        //console.log(this.$store.getters.UserID)

                        //this.$store.commit('SetUserName', 'Yana-Vegana')
                       
                        //Set expire for item
                        Vue.ls.set(this.$store.getters.UserMail, 'РАБОТАЕТ', 15 * 60 * 1000); //expiry 15 minutes
                        //this.errorMsg = ok.message
                        //this.alert = true
                        
                        this.$router.push('/mainScreen/vsevmesteProjList')
                        
                    }, error => {
                        console.log(error.code)
                        this.errorMsg = 'Ошибка, проверьте введенные данные!'
                        this.alert = true
                        this.info = false
                        this.disabled = 0
                        this.$refs.recaptcha.reset()
                    })
                    .catch(function(error) {
                        console.log(error)
                    })
                    
            }
        },
        created () {
            const $script = document.createElement('script')
            $script.async = true
            $script.defer = true
            $script.src = 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
            document.head.appendChild($script)
        },
        computed: {
            loading () {
                return this.$store.getters.loading
            }
        }
    }
</script>
