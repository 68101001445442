import axios from 'axios'
import User from './user_help'
//import date from 'date-and-time';
import store from './store.js'

export default {
    state: {
        user: null,
        userPID: null
    },
    mutations: {
        setUser (state, payload) {
            state.user = payload
        }
    },
    actions: {
        async loginUser ({commit}, {email, password, recaptchaToken}){
            //console.log(123123);
            commit('clearError')
            commit('setLoading', true)
            //console.log(store.state.urlAN + 'recaptcha')
            await axios({
                method: 'POST',
                headers: {
                    'key': 'ANALYTICSNODE.RECA.2a534427844db8a8f7584f14879a6e282a5344278448f7584f1',
                    'recaptchaToken': recaptchaToken,
                    'Content-Type': 'application/json'
                },
                url: store.state.urlAN + 'recaptcha',
                data: []

            })
            .then(async (response) => {
                //console.log(response.status)
                if(response.status === 200){
                    try {

                        let dataTr = [
                            {"u":email, "p":password}]
                        // Logic for using
                        await axios({
                            method: 'POST',
                            headers: {
                                'api_key': store.state.ApiKey,
                                'Content-Type': 'application/json'
                            },
                            url: store.state.urlVSE + 'checkVseVmesteUser',
                            data: dataTr
        
                        })
                        .then(async (response) => {
                            //console.log(response)

                            var body = response.data
                            var s
                            for(let key=0; key < body.length; key++) {  
                                s = body[key] 
                            } 

                            //console.log(s)
                            
                            commit('setUser', new User(s.strMail))
                            store.commit('SetUserMail', s.strMail)
                            store.commit('SetAdmin', s.intAdmin)
                            store.commit('SetUserProjID', s.idProject)
                            store.commit('SetUserID', s.id)
                            commit('setLoading', false)

                        })
                        .catch(function(error) {
                            commit('setLoading', false)
                            commit('setError', 'Ошибка подключения, проверьте введенные данные!')
                            
                            throw error
                        });
                        
                    } catch (error) {
                        // error logic here
                
                        commit('setLoading', false)
                        commit('setError', error.message)
                        
                        throw error
                    }
                } else {
                    throw 'Capcha check ERROR'
                }
                
            })
            
            //console.log(user)
            
        },
        // Logout
        async logoutUser ({commit}) {
            //console.log('LOG OUT!')
            commit('setUser', null)
            
  
        }
    },
    getters: {
        user (state) {
            return state.user
        },
        checkUser (state) {
            //console.log('Check user:' + state.user)
            return state.user !== null
        }
    }
}