import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/store'

import loginForm from '../views/loginForm.vue'
import mainScreen from '../views/mainScreen.vue'
import vsevmesteProjList from '../views/vsevmesteProjList.vue'
import createProject from '../views/createProject.vue'
import editProject from '../views/editProject.vue'


Vue.use(Router)

export default new Router({
  //mode: 'history',
  routes: [
      {
        path: '/',
        name: 'loginForm',
        component: loginForm
      },
      {
          path: '/mainScreen',
          name: 'mainScreen',
          component: mainScreen,
          beforeEnter (to, from, next) {
            //console.log('11 ' + store.getters.checkUser)
            Vue.ls.get(store.getters.UserMail) ? next() : next('/')
          },
          children: [
            
            { 
              path: 'vsevmesteProjList',
              component: vsevmesteProjList,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'createProject',
              component: createProject,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            { 
              path: 'editProject',
              component: editProject,
              beforeEnter (to, from, next) {
                //console.log('12 ' + store.getters.checkUser)
                store.getters.checkUser ? next() : next('/')
              },
            },
            
          ]
      }
  ]
})
