<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import ruMessages from 'devextreme/localization/messages/ru.json';


import {
    locale,
    loadMessages,
    formatMessage
} from 'devextreme/localization';
//import 'devextreme-intl';

export default {
        name: "App",
        data() {
            return {
                locales: [{
                    'Name': 'English',
                    'Value': 'en'
                },
                    {
                        'Name': 'Русский',
                        'Value': 'ru'
                    }
                ]
            }
        },
        created() {
            this.locale = this.getLocale();
            this.initMessages();
            locale(this.locale);
        },
        methods: {
            getLocale() {
                const locale = sessionStorage.getItem('locale');
                return locale != null ? locale : 'ru';
            },
            setLocale(locale) {
                sessionStorage.setItem('locale', locale);
            },
            initMessages() {
                loadMessages(ruMessages);
            },
            changeLocale(e) {
                this.setLocale(e.value);
                document.location.reload();
            },
            formatMessage: formatMessage
        }
    }
</script>
<style src="./css/material.blue-compact.css">
</style>
