<template>
    <v-card class="mx-auto px-6 py-8" max-width="80%" flat>
        <v-card>
            <v-card-title class="bg-amber-lighten-1">
            <h2 class="me-4 font-weight-light">
                Проекты
            </h2>
            </v-card-title>
            <v-card-text>
                Создать новый проект для учета платежей из Getcourse.
            </v-card-text>
        </v-card>
        <br>
        <v-card flat class="mx-auto px-6 py-1">
            
                <form>
                    <v-text-field
                        v-model="uuid"
                        prepend-icon="mdi-identifier"
                        label="ID Проекта"
                        readonly
                    ></v-text-field>
                    <v-text-field
                        v-model="strAbout"
                        prepend-icon="mdi mdi-information-outline"
                        label="Краткое описание"
                        :error-messages="strAboutErrors"
                        required
                        @input="$v.strAbout.$touch()"
                        @blur="$v.strAbout.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="strPaymentsName"
                        prepend-icon="mdi mdi-cart-variant"
                        label="Наименование предложения (Поиск ведется по признаку 'СОДЕРЖИТ')"
                        :error-messages="strPaymentsNameErrors"
                        required
                        @input="$v.strPaymentsName.$touch()"
                        @blur="$v.strPaymentsName.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="numFullPrice"
                        prepend-icon="mdi mdi-cash-multiple"
                        :error-messages="numFullPriceErrors"
                        label="Требуемая максимальная Сумма по проекту"
                        type="number"
                        required
                        @input="$v.numFullPrice.$touch()"
                        @blur="$v.numFullPrice.$touch()"
                    ></v-text-field>
                    <v-text-field
                        v-model="strComment"
                        prepend-icon="mdi mdi-comment-bookmark-outline"
                        label="Комментарий"
                        :error-messages="strCommentErrors"
                        required
                        @input="$v.strComment.$touch()"
                        @blur="$v.strComment.$touch()"
                    ></v-text-field>
                    <br>
                    
                    <v-card
                    class="d-flex justify-space-between mb-6"
                    flat
                    tile
                    >
                        <v-card
                            class="pa-2"
                            flat
                            
                        >
                            <v-btn
                                class="primary"
                                @click="submit"
                                >
                                сохранить проект
                            </v-btn>
                        </v-card>
                        <v-card
                            class="pa-2"
                            flat
                        >
                            <v-btn
                                class="primary"
                                @click="CancelOper"
                                >
                                отмена
                            </v-btn>
                        </v-card>
                    </v-card>
                    
                </form>
           
        </v-card>
    </v-card>
    
    
  </template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { uuid } from 'vue-uuid'; 
import { Buffer } from 'buffer';
import axios from 'axios'
import store from '../store/store.js'
//import DxList, { DxItemDragging } from 'devextreme-vue/list';
//import date from 'date-and-time';

export default {
    mixins: [validationMixin],

    validations: {
        strAbout: { required },
        strPaymentsName: { required },
        numFullPrice: { required },
        strComment: { required },
    },

    data: () => ({
        uuid: uuid.v1(),
        strAbout: '',
        strPaymentsName: '',
        numFullPrice: 0.00,
        strComment: ''
    }),

    computed: {
      strAboutErrors () {
        const errors = []
        if (!this.$v.strAbout.$dirty) return errors
            !this.$v.strAbout.required && errors.push('Обязательное поле. Укажите краткое описание проекта.')
        return errors
      },
      strPaymentsNameErrors () {
        const errors = []
        if (!this.$v.strPaymentsName.$dirty) return errors
            !this.$v.strPaymentsName.required && errors.push('Обязательное поле. Укажите наименование предложения из Геткурса.')
        return errors
      },
      numFullPriceErrors () {
        const errors = []
        if (!this.$v.numFullPrice.$dirty) return errors
            !this.$v.numFullPrice.required && errors.push('Обязательное поле. Укажите максимальную сумму сбора.')
        return errors
      },
      strCommentErrors () {
        const errors = []
        if (!this.$v.strComment.$dirty) return errors
            !this.$v.strComment.required && errors.push('Обязательное поле. Укажите комментарий к проекту.')
        return errors
      },
    },

    methods: {
        submit () {
            let data = []

            axios({
                method: 'POST',
                headers: {
                    'api_key': store.state.ApiKey,
                    'jobUUID': this.uuid,
                    'strAbout': Buffer.from(this.strAbout, 'utf-8').toString('base64'),
                    'strPaymentsName': Buffer.from(this.strPaymentsName, 'utf-8').toString('base64'),
                    'numFullPrice': this.numFullPrice,
                    'strComment': Buffer.from(this.strComment, 'utf-8').toString('base64'),
                    'Content-Type': 'application/json'
                },
                url: store.state.urlVSE + 'updateJobInDB',
                data: data

            })
            .then((response) => {
                //console.log(response.data)
                if(response.data == 'OK')
                {
                    this.$router.push('/mainScreen/vsevmesteProjList')
                }
                else
                {
                    console.log('Error')
                }
            })
        },
        CancelOper () {
            this.$router.push('/mainScreen/vsevmesteProjList')
        },
    },
    async mounted(){
        //this.$store.getters.ProjectUUID
        //console.log(this.$store.getters.ProjectUUID)
        const settingJobUUID = {
            strProjectUUID: this.$store.getters.ProjectUUID,
        }
        await this.$store.dispatch('loadjobProjectsById', settingJobUUID)
        .then(async() => {
        

            //console.log('Chto to1')
            //console.log(this.$store.getters.jobProjectsById[0])
            this.uuid = this.$store.getters.jobProjectsById[0].id
            this.strAbout = this.$store.getters.jobProjectsById[0].strAbout
            this.strPaymentsName = this.$store.getters.jobProjectsById[0].strPaymentsName
            this.numFullPrice = this.$store.getters.jobProjectsById[0].numFullPrice
            this.strComment = this.$store.getters.jobProjectsById[0].strComment

            
        })
    }
    

    
}
</script>

<style>


</style>