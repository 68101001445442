import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './user'
import common from './common'
import jobProjects from './jobProjects'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    urlAN: 'https://api.analyticsnode.ru/apiNode/',
    //urlVSE: 'http://localhost:8480/vsevmesteNode/',
    urlVSE: 'https://api.analyticsnode.ru/vsevmesteNode/',
    ApiKey: 'ANALYTICSNODE.VSEVMESTE.2a284685545668db8a8f7584f14879a6e3216516846518f7584f1',
    UserMail: '',
    IsAdmin: false,
    IsSchoolAdmin: false,
    UserProjID: '',
    UserID: '',
    dateStart: '',
    dateEnd: '',
    UserName: 'Yana-Vegana',
    panelMini: false,
    ProjectUUID: '',
    ProjectCOPY: '',
  },
  mutations: {
    SetPanelMini(state, panelMini) {
      state.panelMini = panelMini
    },
    SetUserMail(state, UserMail) {
      state.UserMail = UserMail
    },
    SetAdmin(state, IsAdmin) {
      state.IsAdmin = IsAdmin
    },
    SetSchoolAdmin(state, IsSchoolAdmin) {
      state.IsSchoolAdmin = IsSchoolAdmin
    },
    SetUserProjID(state, UserProjID) {
      state.UserProjID = UserProjID
    },
    SetUserID(state, UserID) {
      state.UserID = UserID
    },
    SetDateEnd(state, dateEnd) {
      state.dateEnd = dateEnd
    },
    SetDateStart(state, dateStart) {
      state.dateStart = dateStart
    },
    SetUserName(state, UserName) {
      state.UserName = UserName
    },
    SetProjectUUID(state, ProjectUUID) {
      state.ProjectUUID = ProjectUUID
    },
    SetProjectCOPY(state, ProjectCOPY) {
      state.ProjectCOPY = ProjectCOPY
    },
  },
  getters:{
    UserMail: state => state.UserMail,
    IsAdmin: state => state.IsAdmin,
    ProjectUUID: state => state.ProjectUUID,
    ProjectCOPY: state => state.ProjectCOPY,
    IsSchoolAdmin: state => state.IsSchoolAdmin,
    UserProjID: state => state.UserProjID,
    UserID: state => state.UserID,
    dateStart: state => state.dateStart,
    dateEnd: state => state.dateEnd,
    UserName: state => state.UserName,
    panelMini: state => state.panelMini,
  },
  actions: {
  },
  modules: {
    user,
    common,
    jobProjects
  },
  plugins: [createPersistedState()]
})
